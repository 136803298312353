<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                非凸算法正式上线国联证券，赋能机构业务高质量发展
              </p>
              <div class="fst-italic mb-2">2023年5月12日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >国联证券</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/36/国联上线.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    近日，非凸科技与国联证券携手同航，在高性能交易、高效率风控、高质量服务等方面展开深度合作，共同为算法生态建设贡献力量。从立项到上线，双方经过反复沟通、测试，做了缜密时间计划和充足准备工作，最终让机构客户使用到非凸算法。
                  </p>
                  <p>
                    国联证券一直坚持走科技赋能路线，在持续挖掘现有技术潜力的同时，也在积极推进各项新技术的储备。结合非凸科技过硬的技术实力和金融素养，以及对机构业务的深刻理解，促成了国联证券选择非凸算法交易系统上线，为其机构服务提供了强有力的技术引擎。高性能的交易系统不仅能帮助券商减少投入成本、降低成本风险，还能帮助机构客户减少切换成本、提升便捷度。
                  </p>
                  <p>
                    非凸算法交易系统，是基于Rust生态打造的高效率、低延迟、高并发、高稳定性的轻量级交易系统。客户端涵盖监控中心、算法交易、普通委托、结算统计、下载中心、日志输出等功能，加之交易极速、风控完善、操作便捷、界面友好等优势，满足用户对交易性能的极致追求。其中，多账户管理功能，能够方便机构客户在统一终端、同一时间对客户多个账户进行交易策略的执行以及进度监控，极大地提高了交易和管理效率。优秀的超额绩效以及极致的交易体验，获得券商及量化管理人的一致好评。
                  </p>
                  <p>
                    随着资本市场的发展和成熟，机构化趋势日益明显，高性能、低延迟成为刚需。在这场速度的竞赛中，非凸算法交易系统也成为越来越多券商在机构服务赛道上竞跑的“硬核装备”。然而，交易性能的提升离不开软硬件的持续高投入。硬件方面，使用高性能低延时服务器，通信层耗时大幅降低，执行效率极大提升；系统方面，采用Rust技术架构，结合全新的交易业务数据结构模型和最新迭代算法，保证交易系统的极速和稳定。与此同时，非凸科技自建超算中心，致力于高性能计算的前沿开发和技术应用，助力机构客户实现极致速度。
                  </p>
                  <p>
                    在数字化转型浪潮中，金融科技服务商与金融机构的发展，始终在相互成就、相互赋能。作为第三方算法服务商的非凸科技，将持续做到看见需求、满足需求，用自身优势🦶替客户做好价值前瞻。未来，非凸科技与国联证券将继续携手面对行业的发展与变化，共同技术创新和实践应用，为金融业务高质量发展赋能。
                  </p>
                  <p>
                    期待与更多券商展开深入合作，共同成长，实现卓越。
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News36",
};
</script>

<style></style>
